import React from 'react'
import {Menu, Dropdown} from 'antd';
import '../layout.css'
import styled from "styled-components";
import ArrowDrop from '../../assets/ArrowDrop.svg'
import {AnchorLink} from "gatsby-plugin-anchor-links";
const Menu1 = styled(Menu)`
  background-color: #1c1a1a;
  padding: 33px 40px 33px 40px;
  margin-top: 40px;
  margin-left: -290px;
  @media (max-width: 951px) {
    margin-left: -370px;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  vertical-align: bottom;
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

`;


const ContainerCategory = styled.div`
  //margin-bottom: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
    align-items: flex-start;
  }


`;

const ImgText = styled(AnchorLink)`
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */
  color: #FFFFFF;
  padding: 20px;
`;

const TextDrop = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 46px;
  white-space: nowrap;
  /* identical to box height, or 329% */

  :hover {
    border-bottom: 2px solid #4285F4;
    padding-bottom: 40px;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 1350px) {
    font-size: 13px;
  }
  text-transform: uppercase;
  color: #FFFFFF !important;
`;

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    & > div > div > svg > path {
      fill: #4285F4;
      transition: all 0.5s ease-out;
    }
    ${ImgText} {
    color: #4285F4;
    transition: all 0.5s ease-out;
    }
  }
`;


const ForBusinessDropdown = ({data}) => {
    const menu = (
        <Menu1>
            <Container>
                <GridContainer>
                    {data.map((item, index) => {
                        return (
                            <Menu1.Item
                              key={index + "buis"}
                              className="modified-item"
                              style={{ alignItems: "flex-start" }}
                            >
                              <ContainerCategory key={index}>
                                  <AnchorLink stripHash to={item.link}>
                                     <ContainerDiv>
                                      {item.img}
                                      <ImgText stripHash to={item.link}>{item.name}</ImgText>
                                     </ContainerDiv>
                                  </AnchorLink>
                              </ContainerCategory>
                            </Menu1.Item>
                        );
                    })}
                </GridContainer>
            </Container>
        </Menu1>
    )

    return (
        <div style={{paddingLeft: 15, paddingRight: 15, height: 50, zIndex: 1}}>
            <Dropdown overlay={menu}>
                <a href='/' onClick={e => e.preventDefault()}>
                    <TextDrop>Для бизнеса
                        <ArrowDrop className='active' style={{width: 8, height: 5, marginLeft: 5}}/>
                    </TextDrop>
                </a>
            </Dropdown>

        </div>
    )
};

export default ForBusinessDropdown;
