import React from "react";
import NewModal from "./newModal";


const Modal = (props) => {
    return(
        <>
            <NewModal openModal={props.openModal} toggleOpenModal={props.toggleOpenModal}/>
        </>
    )
}

export default Modal;
