import React, {useState} from "react";
import "../layout.css";
import CategoryDropdown from "../Dropdown/category";
import styled from "styled-components";
import Logo from "../../assets/Logo.svg";
import {Button, Menu} from "antd";
import CompanyDropdown from "../Dropdown/company";
import Calc from "../../assets/Calc.svg";
// import phone_img from "../../assets/phone-1568.png";
import white_phone from "../../assets/white_phone.svg";
import MobileBtn from "../Dropdown/mobilebtn";
import {Link} from "gatsby";
import {ContainerHeader} from "../../ui/containers";
import {graphql, useStaticQuery} from "gatsby";
import {ReactSVG} from "react-svg";
import ForBusinessDropdown from "../Dropdown/forbusiness";
import {AnchorLink} from "gatsby-plugin-anchor-links";

// const phone_img = require('../../assets/phone-1568.png')

const {SubMenu} = Menu;

const PhonePlaceholder = styled.div`
  align-self: center;
  margin-left: 50px;
`;

const PhoneTop = styled.div`
  color: white;
  font-size: 14px;
`;

const PhoneBottom = styled.div`
  color: lightgrey;
`;

const HeaderContainer = styled.div`
  margin: 25px auto;
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const ContainerBtn = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Btn = styled(Button)`
  background-color: inherit !important;
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 50px !important;
  /* identical to box height, or 329% */
  padding: 0 !important;
  margin: 15px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 1350px) {
    font-size: 13px;
  }
`;

const CalcPrice = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  cursor: pointer;
`;

const TextCalc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-right: 10px;
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 1200px) {
    font-size: 10px;
    max-width: 80px;
  }
  @media (max-width: 1000px) {
    font-size: 10px;
    max-width: 80px;
    display: flex;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    max-width: 80px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
    max-width: 80px;
  }
  @media (max-width: 320px) {
    font-size: 8px;
    max-width: 80px;
  }
  @media (max-width: 300px) {
    font-size: 8px;
    max-width: 80px;
  }
`;


const MenuGG = styled(Menu)`
  background-color: #4285F4;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) {
    display: none;
  }

`;

const ItemMenu = styled(Link)`
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
`;

const ItemMenu1 = styled(Link)`
  width: 100%;
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemMenuAnchor = styled(AnchorLink)`
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
`;

const ItemMenuButton = styled(AnchorLink)`
  border: none;
  outline: none;
  width: 100%;
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
`;

const PodMenu = styled.div`
  width: 100%;
  background-color: #4285F4;
  padding-top: 30px;
  margin-bottom: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PodItemMenu = styled(Menu.Item)`
  margin: 0;
  padding: 5px 0;

  background-color: #4285F4;
  text-align: center;
`;

const MobilePhonePlaceholder = styled.a`
  font-size: 24px;
  text-align: center;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
  color: white !important;
  :visited{
    color: white !important;  
  }
`;


const DropdownMobile = ({data, title, type}) => {
    return (
        <PodMenu>
            {
                data && data.map((item, counter) =>
                    <PodItemMenu key={item.name + counter + " uniq"} style={{
                        backgroundColor: "#4285F4",
                        textTransform: "none",
                        fontSize: "18px",
                        margin: "15px 0px"
                    }}>

                        {
                            type === "anchor" ?
                                <ItemMenuAnchor stripHash key={item.name + counter + " uniqItem"}
                                                style={{maxWidth: "300px", lineHeight: "19px", padding: "10px 0px"}}
                                                to={item.link}>{item.name}</ItemMenuAnchor>
                                :
                                <ItemMenu key={item.name + counter + " uniqItem"}
                                          style={{maxWidth: "300px", lineHeight: "19px", padding: "10px 0px"}}
                                          to={item.link}>{item.name}</ItemMenu>

                        }
                    </PodItemMenu>
                )
            }
        </PodMenu>

    );
};

const SvgB = styled(ReactSVG)`
  margin-top: -24px;

  & > div > svg {
    margin-top: 24px;
  }

  & > div > svg > path {
    width: 80px;
    fill: #333131;
    transition: all 0.5s ease-out;
  }
`;

const Svg = styled(ReactSVG)`
  width: 50px;
  margin-top: 24px;

  :hover {
    & > div > svg > path {
      fill: #4285F4;
      transition: all 0.5s ease-out;
    }
  }

  & > div > svg {
    width: 50px;
    margin-top: 24px;
  }

  & > div > svg > path {
    fill: #333131;
    transition: all 0.5s ease-out;
  }

`;

const SvgMobilePhone = styled(ReactSVG)`
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;

const Header = () => {
    const [open, setOpen] = useState(false);
    const Marquiz = typeof window !== "undefined" && window.Marquiz;
    const {allStrapiService, allStrapiOption, allStrapiBusiness} = useStaticQuery(graphql`{
        allStrapiService(sort: {fields: serialNumber})  {
            nodes {
                id
                iconSvg {
                    localFile {
                        publicURL
                    }
                }
                title
            }
        }
        allStrapiBusiness {
            nodes {
                blocks {
                    id
                    title
                    IconSvg {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        allStrapiOption {
            nodes {
                NewsVisible
                ShowroomVisible
            }
        }
    }`);

    const BusinessLink = allStrapiBusiness.nodes[0].blocks.map((item, index) => {
        return {
            name: item.title,
            img: <SvgB src={item.IconSvg.localFile.publicURL}/>,
            link: `/business/#business${item.id}`
        };
    });

    const ServiceLink = allStrapiService.nodes.map(item => {
        return {
            name: item.title,
            img: <Svg src={item.iconSvg.localFile.publicURL}/>,
            link: `/services/${item.id}`
        };
    });

    const LINK3 = [
        {
            name: "О нас",
            link: "/about",
        },
        {
            name: "Сотрудничество",
            link: "/cooperation",
        },
        {
            name: "Контакты",
            link: "/contacts",
        }
    ];

    const LINKS1 = [
        {
            type: "dropdown",
            render: <CategoryDropdown key={'category 1'} data={ServiceLink} className="header"/>,
            title: "Умный дом",
            mobileRender: <DropdownMobile key={'DropdownMobile 1'} title="Умный дом" data={ServiceLink}/>
        },
        {
            type: "anchor",
            title: "Проекты",
            link: "/#project"
        },
        {
            type: "link",
            title: "Шоурум",
            link: "/showroom"
        },
        {
            type: "link",
            title: "Новости",
            link: "/news"
        },
        {
            type: "dropdown",
            render: <ForBusinessDropdown key={'business 1'} data={BusinessLink}/>,
            title: "Для бизнеса",
            mobileRender: <DropdownMobile title="Для бизнеса" data={BusinessLink} type={'anchor'}/>
        },
        {
            type: "dropdown",
            render: <CompanyDropdown key={'company 1'} data={LINK3}/>,
            title: "Компания",
            mobileRender: <DropdownMobile title="Компания" data={LINK3}/>
        }
    ];

    const OpenMenu = (value) => {
        setOpen(value);
    };


    return (
        <>
            <ContainerHeader>
                <HeaderContainer>
                    <Link to={"/"}>
                        <Logo className="active adaptive-svg header" style={{width: 209, height: 47, marginTop: 0}}/>
                    </Link>
                    <ContainerBtn>
                        {
                            LINKS1.map((item, count) =>
                                item.type === "dropdown" ? item.render
                                    :
                                    ((item.title === "Шоурум" && !allStrapiOption.nodes[0].ShowroomVisible) || (item.title === "Новости" && !allStrapiOption.nodes[0].NewsVisible)) ?
                                        null
                                        :
                                        item.type === "anchor" ?
                                            <AnchorLink stripHash to={item.link} key={item.title + count + " link"}>
                                                <Btn>
                                                    {item.title}
                                                </Btn>
                                            </AnchorLink> :
                                            <Link to={item.link} key={item.title + count + " link"}>
                                                <Btn>
                                                    {item.title}
                                                </Btn>
                                            </Link>
                            )
                        }

                        <PhonePlaceholder>
                            <PhoneTop>
                                +7 (925) 4077007
                            </PhoneTop>
                            <PhoneBottom>
                                c 10:00 до 20:00
                            </PhoneBottom>
                        </PhonePlaceholder>

                    </ContainerBtn>
                    <div style={{display: "flex"}}>
                        <CalcPrice onClick={() => Marquiz.showModal('60dc9d431568cf003e72e6ca')}>
                            <TextCalc>
                                Рассчитать <br/> стоимость
                            </TextCalc>
                            <Calc className="adaptive-svg-calc header" style={{marginTop: 0}}/>
                        </CalcPrice>
                        <MobileBtn openMenu={OpenMenu}/>
                    </div>
                </HeaderContainer>
                {
                    open === true ? (
                        <div style={{width: "100%", backgroundColor: "#4285F4"}}>
                            <MenuGG
                                style={{
                                    width: "100%",
                                    backgroundColor: "#4285F4",
                                    fontSize: 24,
                                    lineHeight: 48,
                                    textTransform: "uppercase"
                                }}
                                mode="inline"
                                theme="dark"
                            >
                                {

                                    LINKS1.map((item, count) =>
                                        item.type === "dropdown" ?
                                            <SubMenu key={"sub" + count} title={item.title}
                                                     style={{marginBottom: 32, marginTop: 32}}
                                                     className="subMenuMy"
                                            >
                                                {item.mobileRender}
                                            </SubMenu>
                                            :
                                            ((item.title === "Шоурум" && !allStrapiOption.nodes[0].ShowroomVisible) || (item.title === "Новости" && !allStrapiOption.nodes[0].NewsVisible)) ?
                                                null :
                                                item.type === "anchor" ?
                                                    <Menu.Item
                                                        style={{
                                                            marginBottom: 32,
                                                            marginTop: 32,
                                                            backgroundColor: "#4285F4"
                                                        }}
                                                        key={item.title + count + "d"}>
                                                        <ItemMenuButton stripHash
                                                                        to={item.link}>{item.title}</ItemMenuButton>
                                                    </Menu.Item> :
                                                    <Menu.Item
                                                        style={{
                                                            marginBottom: 32,
                                                            marginTop: 32,
                                                            backgroundColor: "#4285F4"
                                                        }}
                                                        key={item.title + count + "d"}>
                                                        <ItemMenu1 to={item.link}>{item.title}</ItemMenu1>
                                                    </Menu.Item>
                                    )

                                }


                            </MenuGG>

                            <MobilePhonePlaceholder href={'tel:+79254077007'}>
                                <img
                                    src={'https://www.englishpatient.org/english-patient-files/484e932a-6d57-4160-ba2e-6c57366896f5.png'}
                                    style={{width: 24, height: 24, marginRight: 10}}/>
                                <span>
                                    +7 (925) 4077007
                                </span>
                            </MobilePhonePlaceholder>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </ContainerHeader>
        </>
    );
};

export default Header;
