import React from 'react'
import { Btn, BtnContainer } from "../components/constants";
import styled from 'styled-components';
import Arrow from "../assets/arrowBack.svg";
import { Link } from "gatsby";

const Links = styled(Link)`
  display: flex;
  align-items: center;
  transition-duration: 0.2s;
  &:hover {
    color: white;
  }
  &:hover {
    & > svg > path {
      fill: ${(props) => (!props.white ? "#ffffffff" : "#4285F4")};
    }
  }

  & > svg > path {
    fill: ${(props) => (!props.white ? "#4285F4" : "#ffffffff")};
  }
`;

const TextButton = styled.div`
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  margin-left: 18px;
  color: inherit;
  position: absolute;
  left: 62px;
  text-align:left;
  text-transform: uppercase !important;
  @media (max-width: 600px) {
    font-size: 13px;
    margin-left: 15px;
  }
`;

const CircleButton = ({ text, link, toggleOpenModal,white }) => {
  return (
    <BtnContainer white={white}>
      {link ? (
        <Links to={link} white={white}>
          <Btn shape="circle" type="primary" size={"large"}>
            <Arrow />
            <TextButton>{text}</TextButton>
          </Btn>
        </Links>
      ) : (
        <Btn
          white={white}
          shape="circle"
          type="primary"
          size={"large"}
          onClick={toggleOpenModal}
        >
          <Arrow />
          <TextButton>{text}</TextButton>
        </Btn>
      )}
    </BtnContainer>
  );
};

export default CircleButton;
