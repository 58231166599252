import React from 'react';
import '../components/layout.css'
import {Modal} from "antd";
import styled from "styled-components";
import Formik from "../components/Form/form";
import CloseIcon from "../assets/close-icon.svg"

const NewModal = ({toggleOpenModal, openModal}) => {
    return (
        <>
            <Modal
                centered
                visible={openModal}
                onOk={toggleOpenModal}
                onCancel={toggleOpenModal}
                width={"100%"}
                closeIcon={<CloseIcon/>}
            >
                <ContentRow>
                    <TextBlock>
                        <TextHeader>
                            МЫ СВЯЖЕМСЯ <br/> С ВАМИ
                        </TextHeader>

                        <TextContent>+7 (910) 4210574</TextContent>
                        <TextContent>с ПН по ПТ 9:00 до 22:00</TextContent>
                    </TextBlock>
                    <FormikWrapper>
                        <Formik white={true}/>
                    </FormikWrapper>
                </ContentRow>
            </Modal>
        </>
    );
};

export default NewModal;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  padding: 0 20px;
  @media (max-width: 1600px) {
    margin-top: 70px;
  }
  @media (max-width: 1200px) {
    justify-content: space-between;
    overflow: hidden;
    margin-right: 120px;
  }
  @media (max-width: 968px) {
    margin-right: 0;
    overflow: inherit;
    display: block;
    margin-top: -100px;
  }
`;

const TextBlock = styled.div`
  color: white;
  font-size: 20px;
  padding-right: 50px;
`

const TextHeader = styled.div`
  font-size: 70px;
  line-height: 110px;
  padding-bottom: 50px;
  @media (max-width: 1468px) {
    font-size: 58px;
  }
  @media (max-width: 968px) {
    font-size: 38px;
    line-height: 40px;
    margin-top: 150px;
  }
  @media (max-width: 568px) {
    font-weight: 300;
    font-size: 26px;
    padding-bottom: 30px;
    margin-top: 130px;
  }
  @media (max-width: 376px) and (max-height: 768px) {
    font-size: 20px;
    margin-top: 120px;
    padding-bottom: 10px;
  }
`;

const TextContent = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 968px) {
    font-size: 16px;
  }
  @media (max-width: 568px) {
    font-weight: 300;
    font-size: 16px;
  }
  @media (max-width: 376px) and (max-height: 768px) {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const FormikWrapper = styled.div`
  margin-top: 170px;
  @media (max-width: 1900px) {
    margin-top: 70px;
  }
  @media (max-width: 968px) {
    margin-top: 42px;
  }
  @media (max-width: 376px) and (max-height: 768px) {
    margin-top: 22px;
  }
`;

