import axios from "axios";

export class RequestAPI {
  static async SendForm(name, phoneNumber, message) {
    let response = {
      error: false,
      msg: ""
    };
    try {
      window.localStorage.setItem("timeRequest", JSON.stringify(new Date().getTime()));
      await axios.post(`https://api.dev.intelbuilding.ru/api/form`, {
        name,
        phoneNumber,
        message
      });
    } catch (e) {
      response.error = true;
      response.msg = e;
      throw response;
    }


  }
}
