import React from "react";
import styled from "styled-components";
import {Divider} from "antd";
import Logotype from "../../images/Logo.svg";
import Instagram from "../../images/Instagram.svg";
import Telegram from "../../images/Telegram.svg";
import Facebook from "../../images/Facebook.svg";
import YouTube from "../../images/YouTube.svg";
import Design from "../../images/ipst-logo.svg";
import Link from "gatsby-link";
import {ContainerFluid, ContainerFooter} from "../../ui/containers";
import {graphql, useStaticQuery} from "gatsby"


const Footer = () => {
    const Marquiz = typeof window !== "undefined" && window.Marquiz;
    const {allStrapiService, allStrapiContacts, allStrapiOption} = useStaticQuery(graphql`{
        allStrapiService(sort: {fields: serialNumber})  {
            nodes {
                id
                title
            }
        }
        allStrapiContacts {
            nodes {
                email
                adress
                phone
                instagram
                youtube
                facebook
                telegram
            }
        }
        allStrapiOption {
            nodes {
                NewsVisible
                ShowroomVisible
            }
        }

    }`)

    return (
        <>
            <Container>
                <ContainerFluid>
                    <BigButton onClick={() => Marquiz.showModal('60dc9d431568cf003e72e6ca')}>Рассчитать стоимость</BigButton>
                </ContainerFluid>
                <ContainerFooter>
                    <BodyOfFooter>
                        <Logo src={Logotype} alt="Logo"/>
                        <Navigation>
                            <LeftColumn>
                                <BigString style={{color: "#7E7E7E", marginBottom: "14px"}}>
                                    умный дом
                                </BigString>
                                {allStrapiService.nodes.map((item, index) => (
                                    <StringLink key={index} to={"/services/" + item.id}>
                                        {item.title}
                                    </StringLink>
                                ))}
                            </LeftColumn>
                            <RightColumn>
                                <BigStringLink to="/#project">проекты</BigStringLink>
                                <BigStringLink to="/business">для бизнеса</BigStringLink>
                                {allStrapiOption.nodes[0].NewsVisible && <BigStringLink to="/news">новости</BigStringLink>}
                                {allStrapiOption.nodes[0].ShowroomVisible && <BigStringLink to="/showroom">шоурум</BigStringLink>}
                                <BigString style={{color: "#7E7E7E"}}>компания</BigString>
                                <StringLink to="/about">О нас</StringLink>
                                <StringLink to="/cooperation">Сотрудничество</StringLink>
                            </RightColumn>
                        </Navigation>
                        <Contacts>
                            <BigStringLink to="/contacts" style={{marginBottom: "10px"}}>
                                контакты
                            </BigStringLink>
                            <String style={{marginLeft: "0", maxWidth: 194}}>
                                {allStrapiContacts.nodes[0].adress}
                            </String>
                            <String style={{lineHeight: "30px", marginLeft: "0"}}>
                                {allStrapiContacts.nodes[0].phone}
                            </String>
                            <String style={{marginLeft: "0"}}>{allStrapiContacts.nodes[0].email}</String>
                            <Icons>
                                <a href={allStrapiContacts.nodes[0].instagram}>
                                    <Icon src={Instagram} alt="Instagram"/>
                                </a>
                                <a href={allStrapiContacts.nodes[0].facebook}>
                                    <Icon src={Facebook} alt="Facebook"/>
                                </a>
                                <a href={allStrapiContacts.nodes[0].telegram}>
                                    <Icon src={Telegram} alt="Telegram"/>
                                </a>
                                <a href={allStrapiContacts.nodes[0].youtube}>
                                    <Icon
                                        style={{marginRight: "0"}}
                                        src={YouTube}
                                        alt="YouTube"
                                    />
                                </a>
                            </Icons>
                        </Contacts>
                    </BodyOfFooter>
                    <IntellectualRights>
                        <MyDivider/>
                        <Abc>
                            <div style={{marginRight: "71%"}}>IntelBuilding 2021</div>
                            <LogoContainer href='https://ipst.com.au/'>
                                <span>Designed by</span>
                                <LogoImage src={Design} alt="Design"/>
                                <LogoCompany>IPST</LogoCompany>
                            </LogoContainer>

                        </Abc>
                    </IntellectualRights>
                </ContainerFooter>
            </Container>
        </>
    );
};


export default Footer;

const LogoCompany = styled.span`
  margin-left: 0px;
  transition: all 0.7s ease-out;
  z-index: 0;
  opacity: 0;
  animation: floatText 5s infinite alternate ease-in-out;
`;


const LogoImage = styled.img`
  margin-left: 10px;
  opacity: 0.5;
  transition: 1s;
  cursor: pointer;
  z-index: 10;

`;

const LogoContainer = styled.a`
  text-decoration: none;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;

  :hover {
    color: #FFFFFF;

    ${LogoCompany} {
      transition: all 0.7s ease-out;
      transform: translateX(30%);
      opacity: 1;
    }

    ${LogoImage} {
      transform: rotate(180deg);
      opacity: 1;
    }

  }
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0D0C0C;
`;

const BigButton = styled.button`
  width: 100%;
  background-color: #4285F4;
  text-align: center;
  text-transform: uppercase;
  height: 150px;
  color: white;
  font-weight: 700;
  font-size: 22px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  transition-duration: .2s;

  &:hover {
    background-color: white;
    color: #4285F4;
  }

  @media screen and (max-width: 1000px) {
    height: 90px;
    font-size: 16px;
  }
`;

const BodyOfFooter = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  margin: 64px 0 71px 10%;
  align-items: flex-start;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 64px 10px 20px 10px;
  }
`;

const Logo = styled.img`
  margin-right: 12%;
  width: 179.5px;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 55px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  width: 30%;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 20px;
    width: 90%;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 23px;
`;

const Icon = styled.img`
  height: 24px;
  margin-right: 45px;
`;

const StringLink = styled(Link)`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 10px;
  color: #FFFFFF;
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;
const String = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 10px;
  color: #FFFFFF;
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;

const BigString = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 46px;
`;

const BigStringLink = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 46px;
  color: #FFFFFF;
`;

const IntellectualRights = styled.div`
  margin: 5px 0 76px 10%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 46px;
  color: white;
  @media screen and (max-width: 1000px) {
    margin: 42px 10px 20px 10px;
  }
`;

const MyDivider = styled(Divider)`
  background-color: #3E3D3D;
  z-index: 2;
  margin: 0 11% 0 0;
  min-width: 0;
  width: auto;
  @media screen and (max-width: 1000px) {
    min-width: 100%;
    margin-bottom: 41px;
  }
`;

const Abc = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
